<template>
  <base-section
    id="info-company"
    class="py-11 pb-5"
  >
      <v-container>
        <v-row>
          <v-col
            cols="6"
            sm="6"
          >
            <v-img
              :src="require(`@/assets/LHG.png`)"
              max-width="100px"
            ></v-img>
            <br>
            <a
              href="/"
              style="color:black;"
            >HOME</a><br>
            <a
              href="/about"
              style="color:black;"
            >ABOUT</a><br>
            <a
              href="/touchfree"
              style="color:black;"
            >TOUCHFREE</a><br>
            <a
              href="/products"
              style="color:black;"
            >PRODUCTS</a><br>
            <a
              href="/contact"
              style="color:black;"
            >CONTACT US</a>
          </v-col>
          <v-col
            cols="6"
            sm="6"
          >
            <h1>Contact</h1>
            <br>
            <h4>PHONE</h4>
            <p>
              (+82) 070-4038-9634
            </p>
            <h4>EMAIL</h4>
            <p
              :style="$vuetify.breakpoint.smAndDown ? 'font-size:0.8em;' : ''"
            >
              touchfree@leehengglobal.com
            </p>
            <h4>ADDRESS</h4>
            <p>
              {{address}}
            </p>
          </v-col>
        </v-row>
      </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionInfoCompany',
    data: () => {
      return {
      }
    },
    computed: {
      address () {
        return this.$t('info.address')
      },
    },
  }
</script>
